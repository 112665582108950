import React from 'react';
// import ReactDOM from 'react-dom';
import { Input, Tooltip } from 'antd';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Radio } from 'antd';
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import image from './Assets/CoffinderLogo.png'
import image2 from './Assets/hot-coffee-close-up-wallpaper-46191-47523-hd-wallpapers.jpg'
import 'antd/dist/antd.css';
import './App.css';

function App() {
  return (
    <div style={{ backgroundImage: `url(${image2})`, backgroundSize: 'auto', width: "100vw", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
      }}>
      <img src={image} height={100} />
      <Input style={{ width: "75vw", marginTop: "375px", borderRadius: "15px"}}
      placeholder="Enter your username"
      prefix={<UserOutlined className="site-form-item-icon" />}
      suffix={
        <Tooltip title="Extra information">
          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)'}} />
        </Tooltip>
      }
    />

      <Input.Password placeholder="input password" style={{ width: "75vw", marginTop: "30px", marginBottom: "30px", borderRadius: "15px" }}/>

      {/* <Input.Password style={{ width: "75vw", marginTop: "20px"}}
        placeholder="confirm password"
        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      /> */}
      <Button href={"/Waiting"} type="default" shape="round" size={'large'} onClick={()=>{console.log("UBIBIBBI")}}>
          Login
        </Button>
      <Button href={"/register"} type="link" size={'large'} style={{color: "#FFFFFF", marginTop: "10px"}}>
            Doesn't have an account? Create one!
      </Button>
    </div>
  );
}

export default App;
