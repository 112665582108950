import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Register from './Register'
import Info from './Info'
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import ProfilePage from './ProfileBuilder';
import Waiting from './Waiting';
import MatchFound from './MatchFound';



ReactDOM.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<Info />} />
      <Route exact path="/Login" element={<App />} />
      <Route exact path="/Register" element={<Register />} />
      <Route exact path="/Profile" element={<ProfilePage />} />
      <Route exact path="/Waiting" element={<Waiting />} />
      <Route exact path="/Found" element={<MatchFound />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
