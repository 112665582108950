// import './App.css';
import CoffinderLogo from './Assets/CoffinderLogo.png'
import coffee from "./Assets/unknown.png"
import 'antd/dist/antd.css';
import { Button } from 'antd';
import { Typography } from 'antd';
import image3 from './Assets/unknown2.png'
import download from './Assets/unknown1.png'
import images from './Assets/talk.jpg'
import { Collapse } from 'antd';
import { useHref } from 'react-router-dom';

function App() {
  const { Title } = Typography; 
  const { Panel } = Collapse;
  return (
    <div
      style={{
        backgroundColor: '#AE8553',
        width: '100vw',
        height: '100vh',
        //textAlign:"center",
        display:"flex",
        flexDirection: 'column',
        alignItems: 'center', 
        //justifyContent: 'center',
        
        //justifyContent: 'flex-end',
        //marginBottom: 30,
        marginTop: 0

      }}
    >
      
      <img src = {CoffinderLogo} style={{width: 300}}/> 
      <img src = {coffee} style = {{width : 300, marginBottom: "20px", marginTop: "10px"}}/>
      <Button type="primary" href={"/login"} style = {{
      backgroundColor: '#392321',
      borderWidth:1,
      alignItems:'center',
      justifyContent:'center',
      width:180,
      height:35,
      borderRadius:15
      }} onClick={()=>{console.log("thdhtdhtdhtd")}}>Log In / Create Account</Button> 
      
      <Title level={2} style = {{
        color: '#FCFBEC', marginTop:'20px'}}> Mission</Title> 
      
      <Title level={3} style = {{
        color: '#FCFBEC',  textAlign:"center"}}> “To connect similar minds through coffee”</Title>

      <Title level={2} style = {{
        color: '#FCFBEC', marginTop: "10px"}}> How It Works</Title> 
 
      
        <Collapse bordered={false} defaultActiveKey={['1']}>
          <Panel header="1. Create an account" key="1">
            {<img src = {image3} style = {{width : 300, borderRadius: "15px"}}/>}
          </Panel>
          <Panel header="2. Choose your interests and availability" key="2">
            {<img src = {download} style = {{width : 300, borderRadius: "15px"}}/>}
          </Panel>
          <Panel header="3. Let us match you with someone" key="3">
            {<img src = {images} style = {{width : 300, borderRadius: "15px"}}/>}
          </Panel>
        </Collapse>,
        
    </div>
  );
}

export default App;