import './App.css';
import image2 from './Assets/IMG_3893_1.png';
import { Button, Radio } from 'antd';


function App() {
    // 392321
  return (
    
    <div style={{backgroundColor: "#AE8553", width:'100vw', height: '100vh', display: "flex", flexDirection: "column", alignItems: "center"}}>
      

        <img src={image2} style = {{height:'250px', width:'250px', marginTop:'50px'}}/>
        
        <text style= {{color:'white',fontSize:'50px',font:'Inter',alignItems:"center", marginTop:"50px"}}>Ipek</text>
        <text style= {{color:'white',fontSize:'20px',font:'Inter',alignItems:"center", marginTop:"30px"}}>Apr 24 10.30am</text>
        <text style= {{color:'white',fontSize:'20px',font:'Inter',alignItems:"center", marginTop:"30px"}}>Efe's Coffee Shop</text>
        <text style= {{color:'white',fontSize:'20px',font:'Inter',alignItems:"center", marginTop:"30px"}}>123 Dr Street, Lansing, MI, 48826</text>
        <br />

        <Button href={"/waiting"} type="Primary" style= {{
            backgroundColor: '#B36666',
            borderWidth: 1,
            alignItems:'center',
            justifyContent: 'center',
            width: 180,
            height: 35,
            borderRadius:15,
            
        }} onClick={()=>{console.log("ththththtasd")}}>Cancel</Button>

    </div> 
    
  );
}

export default App;