import './App.css';
import image from './Assets/CoffinderLogo.png';
import image2 from './Assets/Group_4.png';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Radio } from 'antd';

function App() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div>
      <div style={{backgroundColor: "#AE8553", width:'100vw', height: '100vh', display: "flex", flexDirection: "column", alignItems: "center"}}>
      
        
        <img src={image} style={{height:'100px', marginTop:'50px'}}/>
        
        <text style= {{color:'white',fontSize:'30px',font:'Inter',alignItems:"center", marginTop:"100px", marginBottom:"30px"}}>
          Waiting for you coffee mate...
        </text>

        <Spin indicator={antIcon} />
      
        <Button href={"/found"} type="default" shape="round" size={"100px"} marginTop={"100px"}>
          See what we found
        </Button>

        <img src={image2} style = {{height:'250px', width:'150px', marginTop:'150px'}}/>
        
        </div> 
    </div>
  );
}

export default App;