// import './App.css';
// import { View } from "react";
// import coffinderlogo from "";

// import coffinderlogo from "./";

import React from "react";
import "antd/dist/antd.css";
// import "./index.css";
import { Input, Tooltip, Select, Button } from "antd";
import roundprofile from "./Assets/round_profile_pic.png";
import Title from "antd/lib/typography/Title";

function ProfilePage() {
  const interests_ = [
    "Fishing",
    "Soccer",
    "Basketball",
    "Reading",
    "Politics",
    "Coding",
  ];
  const interests = [];
  const { Option } = Select;
  for (let i = 0; i < interests_.length; i++) {
    interests.push(<Option key={i.toString()}>{interests_[i]}</Option>);
  }

  return (
    <div
      style={{
        backgroundColor: "#AE8553",
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: "25px",
      }}
    >
      <Title
        style={{ marginTop: "20px", color: "#FFFFFF", marginBottom: "0px" }}
      >
        Profile
      </Title>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Input placeholder="First Name" />
          <Input placeholder="Last Name" style={{ marginTop: "20px" }} />
        </div>
        <img src={roundprofile} style={{ padding: "20px", width: "40vw" }} />
      </div>
      <Input placeholder="Phone Number" />
      <Input placeholder="Email" style={{ marginTop: "20px" }} />
      <Title style={{ marginTop: "20px", color: "#FFFFFF" }}>Interests</Title>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="Please select"
        defaultValue={[]}
        // onChange={handleChange}
      >
        {interests}
      </Select>
      <br/>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="Please select"
        defaultValue={[]}
        // onChange={handleChange}
      >
        {interests}
      </Select>
      <Button href={"/waiting"} type="primary" shape="round" style={{backgroundColor:"#392321", marginTop:"50px"}}>Continue</Button>
    </div>
  );
}

export default ProfilePage;